import React from 'react';
import Image from '../Image/Image';
import Header from './Header/Header';
import './Homepage.scss';

class Homepage extends React.Component
{
    viewportAtImg = {};
    loadThreshold;

    constructor(props) {
        super(props);
        this.state = {
            imageLimit: 4,
            images: null
        }
    }

    componentDidMount() {
        fetch('/data/homepage-images.json')
            .then(res => res.json())
            .then(data => {
                this.setState({
                    images: data,
                    loadThreshold: new Date((new Date()).getTime() + 500)
                });
            });
    }

    handleViewEnter=(idx)=> {
        this.viewportAtImg[idx] = true;
        if (idx >= this.state.imageLimit - 2) {
            this.setState(prevState=>({imageLimit:prevState.imageLimit+5}))
        }
    };

    render() {
        let images = this.state.images ? this.state.images.slice(0,this.state.imageLimit) : null;
        return !images|| !this.state.loadThreshold ? null : (
            <section id="homepage" className="photo-container">
                <Header/>
                <div className="photo-wrap">
                    {images.map((img,idx)=>
                        <Image key={idx}
                               onViewEnter={()=>this.handleViewEnter(idx)}
                               className=""
                               imgClassName="anim anim-rotate"
                               fileName={img}/>
                    )}
                </div>
            </section>
        );
    }
}

export default Homepage;


