import React from 'react';
import Navigation from './components/Navigation/Navigation';
import Homepage from './components/Homepage/Homepage';
import Reel from './components/Reel/Reel';
import Filmography from './components/Filmography/Filmography';
import Contact from './components/Contact/Contact';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

class App extends React.Component
{
    render() {
        return (
            <React.Fragment>
                <main>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <Navigation btnRef={this.menuBtnRef}/>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/`} exact component={Homepage}/>
                            <Route path={`${process.env.PUBLIC_URL}/reel`} exact component={Reel}/>
                            <Route path={`${process.env.PUBLIC_URL}/filmography`} exact component={Filmography}/>
                            <Route path={`${process.env.PUBLIC_URL}/contact`} exact component={Contact}/>
                        </Switch>
                    </BrowserRouter>
                </main>
            </React.Fragment>
        );
    }
}

export default App;


