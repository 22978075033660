import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Hamburger from './Hamburger/Hamburger';
import Title from './../Title/Title';
import Socials from './Socials/Socials';
import './Navigation.scss'

class Navigation extends React.Component
{
    links = {
        '/': {color: 'black', name: 'Homepage'},
        '/reel': {color: 'white', name: 'Showreel'},
        '/filmography': {color: 'white', name: 'Filmography'},
        '/contact': {color: 'white', name: 'Contact'},
    };

    btnScrolledClass = 'scrolled';
    ttlScrolledClass = 'scrolled';

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            color: this.links[this.props.location.pathname].color
        };
        this.btnRef = React.createRef();
        this.titleRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll',this.handleWindowScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll',this.handleWindowScroll);
    }

    handleWindowScroll=()=> {
        let btn = this.btnRef.current;
        let ttl = this.titleRef.current;
        if (window.scrollY > 100) {
            !btn.classList.contains(this.btnScrolledClass) && btn.classList.add(this.btnScrolledClass);
            !ttl.classList.contains(this.ttlScrolledClass) && ttl.classList.add(this.ttlScrolledClass);
        } else if (window.scrollY <= 100) {
            btn.classList.contains(this.btnScrolledClass) && btn.classList.remove(this.btnScrolledClass);
            ttl.classList.contains(this.ttlScrolledClass) && ttl.classList.remove(this.ttlScrolledClass);
        }
    };

    handleToggle=()=>
        this.setState(prevState=>({open:!prevState.open}));

    handleClick=(e)=> {
        e.preventDefault();
        const link = e.target.getAttribute("href");
        window.scrollTo(0,0);
        this.props.history.push(link);
        this.setState({color: this.links[link].color, open: false})
    };

    render() {
        let elementClass = classNames(this.state.color, {'active': this.state.open});
        return (
            <React.Fragment>
                <Title ref={this.titleRef}/>
                <nav id="main-nav" className={elementClass}>
                    <Hamburger ref={this.btnRef} onToggle={this.handleToggle}/>
                    <div className="menu-content">
                        <ul>
                            {Object.keys(this.links).map((key)=>
                                <li key={key}>
                                    <a href={key} onClick={this.handleClick}>{this.links[key].name}</a>
                                </li>
                            )}
                        </ul>
                        <Socials/>
                    </div>
                </nav>
            </React.Fragment>
        );
    }
}

export default withRouter(Navigation);


