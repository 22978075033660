import React from 'react';
import './header.scss'

const Header=(props,ref)=>
    <header className="position-relative">
        <video width="100%" autoPlay={true} loop muted playsInline={true} poster={`${process.env.PUBLIC_URL}/video/srbova_homepage.jpg`}>
            <source src={`${process.env.PUBLIC_URL}/video/srbova_homepage.mp4`} type="video/mp4"/>
            Your browser does not support HTML5 video.
        </video>
    </header>;

export default Header;


