import React from 'react'
import './Title.scss'

const Title=React.forwardRef((props,ref)=>
    <div ref={ref} className={`title ${window.location.pathname === '/' ? 'title-homepage' : ''}`}>
        <h1>Tereza Srbova</h1>
        <div>actress</div>
    </div>);

export default Title;
