import React from 'react'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'
import Loading from '../Loading/Loading'
import './Image.scss'

class Image extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {loaded: false, inView: false};
        this.eventSent = false;
    }

    componentDidUpdate() {
        if (!this.eventSent && this.state.inView && this.state.loaded && this.props.onViewEnter) {
            this.props.onViewEnter();
            this.eventSent = true;
        }
    }

    handleLoad=()=> 
        !this.state.loaded && this.setState({loaded: true});

    handleInView=(state)=>
        this.state.loaded && this.setState({inView:state});

    render() {
        return (
            <InView onChange={this.handleInView}>
                {({inView,ref}) => {
                    let elementClass = classNames(this.props.className, {
                        'loading': !this.state.loaded,
                        //'in-view': this.state.loaded && inView
                    });
                    let imgClass = classNames(this.props.imgClassName, {
                        'invisible': !this.state.loaded,
                        'in-view': this.state.loaded && inView
                    });
                    return (
                        <figure ref={ref} className={elementClass}>
                            {!this.state.loaded && <Loading/>}
                            <picture onLoad={this.handleLoad}>
                                <source media="(min-width: 1200px)" srcSet={`${process.env.PUBLIC_URL}/img/1920/${this.props.fileName}`}/>
                                <source media="(min-width: 769px)" srcSet={`${process.env.PUBLIC_URL}/img/1200/${this.props.fileName}`}/>
                                <source media="(min-width: 420px)" srcSet={`${process.env.PUBLIC_URL}/img/768/${this.props.fileName}`}/>
                                <source srcSet={`${process.env.PUBLIC_URL}/img/420/${this.props.fileName}`}/>
                                <img className={imgClass} src={`${process.env.PUBLIC_URL}/img/1920/${this.props.fileName}`} alt={this.props.src||'photography'}/>
                            </picture>
                        </figure>
                    );
                }}
            </InView>
        );
    }
}

export default Image;