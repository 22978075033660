import React from 'react';
import './Hamburger.scss';

const Hamburger=React.forwardRef((props,ref)=>
    <button ref={ref} className={`hamburger hamburger-arrow ${window.location.pathname === '/filmography' ? 'hamburger-filmography' : ''} ${props.active?'active':''}`} type="button" onClick={props.onToggle}>
        <span className="hamburger-box">
            <span className="hamburger-inner"/>
        </span>
    </button>);

export default Hamburger;


