import React from 'react';
import { FaInstagram, FaTwitter } from 'react-icons/fa'
import './Socials.scss';

const Socials=(props,ref)=>
    <div className="social">
        <a href="https://www.instagram.com/terezasrbova_x" target="_blank" rel="noopener noreferrer">
            <FaInstagram/>
        </a>
        <a href="https://twitter.com/xterezasrbova" target="_blank" rel="noopener noreferrer">
            <FaTwitter/>
        </a>
    </div>;

export default Socials;


