import React from 'react';
import './Contact.scss'

class Contact extends React.Component
{
    render() {
        return (
            <section className="contact">
                <div className="contact-content">
                    <h1>Contact</h1>

                    <div>
                        <h2>Georg Georgi<br/>
                        Das Imperium Talent Agency Berlin (D.I.T.A.)</h2>
                        Torstraße 129<br/>
                        3rd floor, front house<br/>
                        10119 Berlin<br/>
                        Germany<br/>
                        Tel: +49 151 6195 7519<br/>
                    </div>

                    <div>
                        <h2>Vanessa Biermannova</h2>
                        1. herecka agentura<br/>
                        Žitná 610/23, 110 00 Praha 1, Czech Republic<br/>
                        +420 602 237 168<br/>
                        info@prvniha.com<br/>
                        <a href={"https://www.prvniha.com/cz"} target={"_blank"} rel={"noreferrer"}>https://www.prvniha.com/cz</a>
                        {/*<h2>Marion Kammer<br/>
                        TalentWorks LA</h2>
                        3500 W Olive Ave<br/>
                        Suite 1400<br/>
                        Burbank California 91505<br/>
                        Tel: 818-972-4300<br/>*/}
                    </div>

                </div>
            </section>
        );
    }
}

export default Contact;


