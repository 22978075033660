import React from 'react';
import { FaFilm, FaTv } from 'react-icons/fa';
import './Filmography.scss';

class Filmography extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {filmography:null}
    }

    componentDidMount=()=> 
        fetch('/data/filmography.json')
            .then(res=>res.json())
            .then(data=>this.setState({filmography:data}));

    render=()=> !this.state.filmography ? null : (
        <section className="filmography">
            <div className="filmography-container">
                {this.state.filmography.map((film,idx)=>(
                    <div key={idx} className="filmography-row">
                        <div className="filmography-icon">
                            {film.type==='film'?<FaFilm/>:<FaTv/>}
                        </div>
                        <div className={`filmography-year ${film.to?'small':''}`}>
                            {film.year}
                            {film.to?(<div>{film.to}</div>):null}
                        </div>
                        <div className="">
                            <h2>{film.name}</h2>
                            <span className="filmography-as-a">{film.as}</span>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Filmography;


