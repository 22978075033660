import React from 'react';
import './Reel.scss';

class Reel extends React.Component
{
    render() {
        return (
            <section id="reel">
                <div className="vimeo-wrap">
                    <iframe src="https://player.vimeo.com/video/315856326?autoplay=1&loop=1&color=ffffff&byline=0&portrait=0"
                            title="Reel"
                            frameBorder="0"
                            allow="autoplay; fullscreen"
                            allowFullScreen/>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
            </section>
        );
    }
}

export default Reel;


